<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-card>
        <b-table
          :fields="fields"
          :items="rows"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          :filter="filter"
          no-local-sorting
          responsive
        >
          <template v-if="!(data.item.id === 'empty')" slot="name" slot-scope="data">
            <expander
              :level="data.item.level"
              :has-items="hasItems(data.item)"
              :expanded="data.item._expanded"
              @expand="expand(data.item)"
            >
              <template slot="text">
                <img v-if="data.item.avatar" class="user-avatar" :src="data.item.avatar" />
                <span
                  style="margin-right: 15px; width: 330px"
                  :class="{ bold: data.item.isBold }"
                  @click="expand(data.item)"
                >
                  {{ data.item.name || 'Не указан' }}
                  <span v-if="data.item.entity" style="opacity: 0.7">({{ data.item.entity.name.toLowerCase() }})</span>
                </span>
                <span v-if="data.item['_links']" v-for="(link, alias) in data.item['_links']"
                  >| <a :href="link.link" target="_blank">{{ link.name }}</a></span
                >
              </template>
            </expander>
          </template>

          <template v-if="!(data.item.id === 'empty')" slot="average" slot-scope="data">
            <span :class="{ bold: data.item.isBold }">
              <template v-if="!data.item.isPercent">
                {{ (data.item.average || 0) | formatNumber('0,[00]') }}
              </template>
              <template v-else> {{ (data.item.average || 0) | formatNumberNORMAL(2, ',', '') }}% </template>
            </span>
          </template>

          <template v-if="!(data.item.id === 'empty')" slot="budget" slot-scope="data">
            <popover :target="data.item.id + '_summary'">
              <popup v-if="popups[data.item.id + '_summary']"></popup>
            </popover>
            <a target="_blank" :href="data.item.link || '#'" :class="{ link: true, bold: data.item.isBold }">
              <template v-if="data.item.count > 0"> {{ data.item.count }} | </template>

              <template v-if="!data.item.isPercent">
                {{ (data.item.budget || 0) | formatNumber('0,[00]') }}
              </template>
              <template v-else> {{ (data.item.budget || 0) | formatNumberNORMAL(2, ',', '') }}% </template>

              <template v-else>-</template>
              <template v-if="data.item.percent"> ({{ (data.item.percent || 0) | formatNumber() }}%) </template>
              <template v-else></template>
            </a>
          </template>

          <template v-if="!(data.item.id === 'empty')" v-for="date in months" :slot="'month_' + date" slot-scope="data">
            <template v-if="data.item.dates && data.item.dates[date] && data.item.dates[date].budget">
              <popover :target="data.item.id + '_' + date"> asdasd </popover>
              <a
                target="_blank"
                :href="data.item.dates[date].link || '#'"
                :class="['link', 'green', { bold: data.item.isBold }]"
                :id="data.item.id + '_' + date"
              >
                <template v-if="data.item.dates[date].count > 0"> {{ data.item.dates[date].count }} | </template>

                <template v-if="!data.item.isPercent">
                  {{ data.item.dates[date].budget | formatNumber() }}
                </template>
                <template v-else> {{ data.item.dates[date].budget | formatNumberNORMAL(2, ',', '') }}% </template>

                <template v-if="data.item.dates[date].percent">
                  ({{ (data.item.dates[date].percent || 0) | formatNumber() }}%)
                </template>
                <template v-else></template>
              </a>
            </template>
            <template v-else>-</template>
          </template>
        </b-table>
      </b-card>
    </template>
  </list>
</template>

<script>
  import api from '@/assets/js/api'
  import remove from 'lodash/remove'
  import Popup from './Popup'

  import List from '../../../components/List'
  import Popover from '../../../components/PopoverAlive'
  import Expander from '../../../components/Expander'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'

  export default {
    name: 'report',
    components: {
      Expander,
      Popover,
      List,
      Popup,
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        defaultAvatar: '/static/img/avatars/default.jpg',
        popups: {},
        fields: [
          {
            key: 'name',
            label: '&nbsp;',
            class: 'text-left',
          },
          {
            key: 'average',
            label: 'Среднее',
            class: 'text-center',
          },
          {
            key: 'budget',
            label: 'Сумма',
            class: 'text-center',
          },
        ],
        months: [],
        filters: [
          {
            label: 'Тип сделки',
            name: 'lead_type_id',
            type: 'select',
            items: [],
            width: 2,
            multiple: true,
          },
          {
            name: 'date',
            type: 'date',
            range: true,
          },
        ],
      }
    },
    beforeRouteEnter(to, from, next) {
      Promise.all([
        api.base.get({
          path: 'fields/2',
        }),
      ]).then(result => {
        let fields = result[0]

        next(vm => {
          let filters = vm.filters

          fields.fields.forEach(field => {
            if (field.alias === 'lead_type') {
              filters.find(filter => {
                return filter.name === 'lead_type_id'
              }).items = field.enums.map(fieldEnum => {
                return {
                  value: fieldEnum.id,
                  name: fieldEnum.value,
                }
              })
            }
          })
        })
      })
    },
    methods: {
      showDetails(e, item, date) {
        if (!item.popup) {
          e.preventDefault()

          console.log(this.$refs)

          setTimeout(() => {
            this.$set(item, 'popup', 'test')

            this.$root.$emit('bv::show::popover', item.id + '_' + date)
          }, 2000)
          // e.$emit('bv::popover::show');
        }
      },
      hideDetails(key) {
        if (this.popups[key]) {
          this.$set(this.popups, key, false)
        }
      },

      resultHandler(result) {
        result.items = this.dataPrepare(result)

        this.months = result._months

        remove(this.customHeader, ch => {
          return ch.custom
        })

        remove(this.fields, ch => {
          return ch.custom
        })

        result._months.forEach((month, key) => {
          let mName = moment(month).format('MMMM YY')

          this.fields.push({
            custom: true,
            key: 'month_' + month,
            label: mName,
            class: 'text-center relative',
          })
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .user-avatar {
      margin-right: 5px;
    }
    .bold {
      font-weight: 900;
    }

    .relative {
      position: relative;
    }

    .link {
      color: #20a8d8 !important;
      &:hover {
        color: #2196f3 !important;
      }
      cursor: pointer;
      line-height: 1;
      display: inline-block;
    }
  }
</style>
