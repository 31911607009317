<template>
  <div :class="['table-popup', { visible: true }]">
    <h2>Заглушка</h2>
  </div>
</template>

<script>
  import api from '../../../assets/js/api'

  export default {
    data() {
      return {
        info: {},
      }
    },
    props: {
      projectId: {
        default: undefined,
      },
      clientId: {
        default: undefined,
      },
      leadType: {
        default: undefined,
      },
      start: {
        default: undefined,
      },
      end: {
        default: undefined,
      },
    },
    mounted() {},
  }
</script>

<style lang="scss" scoped>
  .table-popup {
    text-align: left;
    bottom: 100%;
    background: #fff;
    border-radius: 3px;
    line-height: 1;
    color: #000;
    padding: 0 20px;
    display: flex;

    &.visible {
      display: block;
    }

    h2 {
      font-size: 1.3rem;
      margin: 0;
      padding: 0 0 7px 0;
      white-space: nowrap;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0 0 20px 0;

      li {
        display: block;
        padding-bottom: 5px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
</style>
